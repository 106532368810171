.shelf-container {
  display: flex;
  flex-wrap: wrap;
  width: 85%;
  min-height: 600px;

  &-header {
    width: 100%;
    margin-bottom: 10px;

    .products-found {
      float: left;
      margin: 0;
      margin-top: 8px;
    }

    .sort {
      float: right;

      select {
        background-color: #fff;
        outline: none;
        border: 1px solid #ececec;
        border-radius: 2px;
        margin-left: 10px;
        width: auto;
        height: 35px;
        cursor: pointer;

        &:hover {
          border: 1px solid #5b5a5e;
        }
      }
    }
  }

  .shelf-item {
    width: 24%;
    position: relative;
    text-align: center;
    box-sizing: border-box;
    padding: 1px;
    margin-bottom: 30px;
    margin: 5px;
    border: 1px solid transparent;
    cursor: pointer;
    -webkit-box-shadow: 2px 5px 16px 0px #0B325E, 5px 5px 15px 5px rgba(0,0,0,0); 
box-shadow: 2px 5px 16px 0px #0B325E, 5px 5px 15px 5px rgba(0,0,0,0);
    background-color: white;
    &:hover {
      border: 1px solid #eee;

      .shelf-item__buy-btn {
        background-color: #eabf00;
      }
    }

    .shelf-stopper {
      position: absolute;
      color: #ececec;
      top: 10px;
      right: 10px;
      padding: 5px;
      font-size: 0.6em;
      background-color: #1b1a20;
      cursor: default;
    }

    &__thumb {
      height: 300px;
      img {
        width: 100%;

        height: 100%;
       
        object-fit: scale-down;
      }
      
    }
    p{
      height: 4rem;
    }

    // &__title {
    //   position: relative;
    //   padding: 0 20px 50px;
    //   height: 45px;

    //   &::before {
    //     content: '';
    //     width: 20px;
    //     height: 2px;
    //     background-color: #eabf00;
    //     position: absolute;
    //     bottom: 0;
    //     left: 50%;
    //     margin-left: -10px;
    //   }
    // }

    &__price {
      height: 40px;

      .val {
        b {
          font-size: 1.5em;
          margin-left: 5px;
        }
      }

      .installment {
        color: #9c9b9b;
      }
    }

    &__buy-btn {
      background-color: #1b1a20;
      color: #fff;
      padding: 15px 0;
      margin-top: 10px;
      cursor: pointer;
      // border-bottom: 2px solid #151419;

      transition: background-color 0.2s;
    }
  }
}
$breakpoint-tablet: 768px;
@media (max-width: $breakpoint-tablet) {
  .shelf-item {
    min-width: 96vw;
    border: 1px solid #5b5a5e;
  }
  .shelf-container { 
    
    width: 100%;
    
  }

}
.shelf-item-codeAndQty{
 margin: 0 1rem 0 1rem;
 display: flex;
 justify-content: space-between;
 font-size: 0.5rem;
 font-weight: 900;
}
.shelf-item-code {

  text-align: left;

}
.shelf-item-Qty {
  
  text-align: right;
   
}